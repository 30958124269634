// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barn-js": () => import("./../../../src/pages/barn.js" /* webpackChunkName: "component---src-pages-barn-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dorlands-country-house-js": () => import("./../../../src/pages/dorlands-country-house.js" /* webpackChunkName: "component---src-pages-dorlands-country-house-js" */),
  "component---src-pages-dorlands-house-js": () => import("./../../../src/pages/dorlands-house.js" /* webpackChunkName: "component---src-pages-dorlands-house-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-frankys-hide-out-js": () => import("./../../../src/pages/frankys-hide-out.js" /* webpackChunkName: "component---src-pages-frankys-hide-out-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-to-do-js": () => import("./../../../src/pages/to-do.js" /* webpackChunkName: "component---src-pages-to-do-js" */),
  "component---src-pages-weddings-js": () => import("./../../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

